@import "../../../styles/base/abstracts";

.hbs-cta-link {
  align-items: baseline;
  display: inline-flex;

  .hbs-cta-link__icon--external {
    transform: rotate(-45deg);
  }

  @include hover {
    .hbs-cta-link__icon--arrow {
      .arrow {
        animation: swipe 0.5s ease-in-out;
      }
    }

    .hbs-cta-link__icon--download {
      .download-arrow {
        animation: rollDown 0.5s ease-in-out;
      }
    }
  }
}

.hbs-cta-link__text {
  @include body-text($ff-sans);
  flex-shrink: 0;
  font-weight: $fw-semibold;
  margin-bottom: 0;
  max-width: 100%;
}

.hbs-cta-link__icon {
  @include size(0.7em);
  margin-left: $spacing-xs;
  flex-shrink: 0;
  transform: translateY(-1px);
  transition: transform $transition;

  &--arrow {
    .arrow {
      transition: transform $transition;
    }
  }
}

.hbs-cta-link--link {
  @include color-link($reverse: true);

  &.hbs-cta-link--nested {
    @include unlink;
    color: inherit;
    transition: none;
  }

  a:hover &.hbs-cta-link--nested,
  a:focus &.hbs-cta-link--nested {
    .hbs-cta-link__icon--download {
      .download-arrow {
        animation: rollDown 0.5s ease-in-out;
      }
    }
    .hbs-cta-link__icon--arrow {
      transform: rotate(-45deg);
    }
  }
}

.hbs-cta-link--primary-button,
.hbs-cta-link--secondary-button {
  @include unlink;
  border-radius: 3px;
  padding: 9px 15px;
  transition: $transition;

  &:focus:focus-visible {
    outline-offset: 4px !important;
  }

  @include mq($bp-tablet-xl) {
    padding: 12px 20px;
  }

  .hbs-cta-link__text-nowrap {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 100%;

    .hbs-cta-link__text {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-shrink: 1;
    }

    svg {
      transform: translateY(0em);
    }
  }
}

.hbs-cta-link--primary-button {
  background-color: $c-spot;
  color: $c-bg;

  @include hover {
    border-color: $c-text;
    background-color: $c-text;
    color: $c-bg;
  }
}

.hbs-cta-link--secondary-button {
  box-shadow: inset 0 0 0 1px;

  @include hover {
    color: $c-spot;
  }
}

.hbs-cta-link--small {
  @include small-title;
  align-items: center;

  .hbs-cta-link__text {
    @include small-title;
  }
}

.hbs-cta-link--small.dropdown {
  margin-right: $spacing-md;
}

.hbs-cta-link__text .hbs-cta-link__text-last {
  display: inline-block;
}
